console.log(process.env.NODE_ENV, window.location.origin, 22);
// yuankong-ai 测试
// yuankongai  正式
let apis = {}
if (process.env.NODE_ENV == 'development') {
    apis = {
        api1: 'https://www.yuankong-ai.com/api',
        api2: 'https://data.yuankong-ai.com', //1111111
        api3: 'https://agent.yuankong-ai.com', //测试
        // api3: "https://43.153.89.112:5000", //测试
        api4: "http://18.246.191.34:8100",   ///11111111
        api5: "https://aicanvas.yuankong-ai.com",
        // api6: "https://43.153.89.112:5000",
        api6: "https://app.yuankong-ai.com",

        // tq测试域名
        api7: "http://139.196.243.222:5005"
    }
} else {
    if (window.location.origin == 'https://chat.yuankong-ai.com') {
        apis = {
            api1: 'https://www.yuankong-ai.com/api',
            api2: 'https://data.yuankong-ai.com', //1111111
            api3: 'https://agent.yuankong-ai.com', //测试
            // api3: "https://43.153.89.112:5000", //测试
            api4: "http://18.246.191.34:8100",   ///11111111
            api5: "https://aicanvas.yuankong-ai.com",
            // api6: "https://43.153.89.112:5000",
            api6: "https://app.yuankong-ai.com",
            // tq测试域名
            api7: "https://agent.yuankong-ai.com"
        }
    } else {
        apis = {
            api1: 'https://www.yuankongai.com/api',
            api2: 'https://data.yuankongai.com', //1111111
            api3: 'https://agent.yuankongai.com', //测试
            // api3: "https://43.153.89.112:5000", //测试
            api4: "http://18.246.191.34:8100",   ///11111111
            api5: "https://aicanvas.yuankongai.com",
            // api6: "https://43.153.89.112:5000",
            api6: "https://app.yuankongai.com",
            api7: "https://agent.yuankongai.com"
        }
    }

}
export { apis }
