
export default {
    global: {
        home: '主页',
        tools: '工具',
        myForm:'我的表格',
        bot: '我的机器人',
        templates: '机器人广场',
        analysis: '社媒洞察',
        data: '数据',
        tutorials: '教程',
        APIkeys: 'API密钥',
        document: '文件',
    },
      // 主页--配置
      startPage: {
        top_swiper: [
            {
                title: '仅通过聊天，来操控您的Excel表格',
                illustrate: '全球2000W+用户的选择'
            },
            // {
            //     title: '仅通过聊天，来操控您的Excel表格2',
            //     illustrate: '全球2000W+用户的选择2'
            // }
        ],
        table_config: {
            h4: '我常用的ChatExcel表格',
            btn: '创建表格'
        },
        tableColumn: ['最近表格', '负责人', '上次打开时间'],
        can_do_config: {
            h4: '你可以用ChatExcel做这些',
            btn: '查看更多'
        },
        dosomething_config:[
            {
                img:'img_1',
                tit_p:'杂乱数据排序',
                instruct_tit:'指令词模版',
                instruct_span1:'按联系邮箱的首字母升序排序',
                instruct_span2:'按联系邮箱的首字母升序排序',
                btn:'杂乱数据排序练习表格'
            },
            {
                img:'img_2',
                tit_p:'批量中译英内容',
                instruct_tit:'指令词模版',
                instruct_span1:'新增一列为：“美食英文”',
                instruct_span2:'将所有“特色美食”翻译为英文，结果添加到“英文列”',
                btn:'批量中译英内容练习表格'
            },
            {
                img:'img_3',
                tit_p:'清洗与筛选制定数据',
                instruct_tit:'指令词模版',
                instruct_span1:'GDP降序排列',
                instruct_span2:'GDP排名前5的省份/GDP排名5-10的省份',
                btn:'清洗与筛选制定数据练习表格'
            },
            {
                img:'img_4',
                tit_p:'基础运算—求和',
                instruct_tit:'指令词模版',
                instruct_span1:'将“云南省”“广东省”和“天津”的2022GDP相加',
                instruct_span2:'将秦岭淮河以北省市的2022GDP相加',
                btn:'基础运算—求和练习表格'
            }
        ]
    },
    // 我的表格
    myFormPage:{
        title:'快速创建',
        change_file:'选择本地文件',
        import_file:'导入练习表格',
        common_use:'我常用的ChatExcel表格',
        tableColumn: ['最近表格', '负责人', '上次打开时间','操作'],
    }
}
