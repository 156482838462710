<template>
  <div class="siderBar">
    <img style="width: 98px;height: 35px; margin: 0 0 21px  30px" src="@/assets/images/logoAi.png" />
    <div v-for="(item, index) in menuList" :key="index">
      <div :class="`mentItem ${item.src == $route.meta.menu ? 'active' : ''}`" @click="selectMenu(item, index)">
        <img v-if="item.src != $route.meta.menu" :src="require('../../assets/images/' + item.icon)" alt="">
        <img v-else :src="require('../../assets/images/' + item.activeIcon)" alt="">
        <div class="title">{{ $t(`global.${item.title}`) }}</div>
      </div>
       <div v-if="index==0||index==1" style="width: 100%; height: 1px;border-top: 1px solid rgba(226, 226, 226, 1);margin: 10px 0;">
    </div>
    </div>
    <!-- <div style="width: 100%; height: 1px;border-top: 1px solid rgba(226, 226, 226, 1);margin: 10px 0;">
    </div> -->
    <!-- <div class="Waitlist commentTag">
      Waitlist for Prompt Studio
    </div>
    <div class="help commentTag">
      Need help? Talk to our team
    </div>
    <div class="community commentTag">
      Join the YuanKong AI community
    </div> -->
    <div class="bottomMsg">
      <img src="@/assets/images/bottomMsg.png" alt="" style="width: 100%;height: 42px;">
    </div>
  </div>
</template>

<script>
import { getInstances } from "@/api/user";
import { getCookie, setCookie } from "@/utils/cookie";
export default {
  data() {
    return {
      rankingId: "",
      menuList: [
        {
          title: 'home',
          icon: 'home.png',
          activeIcon: 'homeActive.png',
          src: 'start'
        },
        // {
        //   title: 'tools',
        //   icon: 'tools.png',
        //   activeIcon: 'toolsActive.png',
        //   src: 'tools'
        // },
           {
          title: 'myForm',
          icon: 'myForm.svg',
          activeIcon: 'myFormActive.svg',
          src: 'myForm'
        },
        {
          title: 'bot',
          icon: 'agents.png',
          activeIcon: 'agentsActive.png',
          src: 'bot'
        },
         {
          title: 'templates',
          icon: 'templates.png',
          activeIcon: 'templatesActive.png',
          src: 'templates',
        },
         
        {
          title: 'analysis',
          icon: 'templates.png',
          activeIcon: 'templatesActive.png',
          src: 'analysis',
        },
        {
          title: 'data',
          icon: 'data.png',
          activeIcon: 'dataActive.png',
          src: 'data'
        },
      
        // {
        //   title: 'tutorials',
        //   icon: 'tutorials.png',
        //   activeIcon: 'tutorialsActive.png',
        //   src: 'tutorials',
        //   disabled: true
        // },
        // {
        //   title: 'APIkeys',
        //   icon: 'apikeys.png',
        //   activeIcon: 'apikeysActive.png',
        //   src: 'apikeys',
        //   disabled: true
        // },
        // {
        //   title: 'document',
        //   icon: 'document.png',
        //   activeIcon: 'documentActive.png',
        //   src: 'document',
        //   disabled: true
        // }
      ],
      activeIndex: 0
    };
  },
  mounted() {
    // this.$store.dispatch("conversationLog");
    // this.$store.dispatch("getMenus");
  },
  methods: {
    selectMenu(item, index) {
      if (item.disabled) {
        return
      }
      this.activeIndex = index
      this.$router.push({
        path: `/${item.src}`,
        query: {
          instanceID: item.instanceID,
        },
      });
    },

  },
};
</script>

<style scoped>
.siderBar {
  padding: 10px 10px;
  max-width: 240px;
  height: 100%;
  position: relative;
}

.bottomMsg {
  position: absolute;
  bottom: 0;
  left: 6px;
}

.mentItem {
  display: flex;
  align-items: center;
  height: 40px;
  padding: 0 21px;
  cursor: pointer;
  border-radius: 3px;
  border: 1px solid transparent;
  color: rgba(127, 134, 146, 1);
  margin-bottom: 3px;
}

.mentItem.active {
  /* border: 1px solid #E1E4EA; */
  background: #fff;
  color: rgba(0, 0, 0, 1);
}

.mentItem .title {
  font-family: Helvetica Neue;
  font-size: 14px;
  font-weight: 500;
}

.mentItem.active .title {
  color: rgba(0, 0, 0, 1);
}

.mentItem img {
  width: 14px;
  height: 14px;
  vertical-align: bottom;
  margin-right: 16px;
}

.commentTag {
  width: 220px;
  height: 28px;
  font-family: Helvetica Neue;
  font-size: 12px;
  font-weight: 400;
  text-align: center;
  margin-top: 20px;
  line-height: 28px;
}

.Waitlist {
  background: rgba(233, 244, 255, 1);
  color: rgba(77, 120, 163, 1);
}

.help {
  background: rgba(218, 248, 234, 1);
  color: rgba(26, 179, 137, 1);
}

.community {
  background: rgba(254, 238, 229, 1);
  color: rgba(255, 136, 0, 1);
}
</style>