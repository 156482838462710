export default {
    global: {
        home: 'トップページ',
        tools: 'ツール',
        myForm:'我的表格',
        bot: '代理人',
        data: 'データ＃データ＃',
        templates: 'テンプレート',
        analysis: '社媒洞察',
        tutorials: '指導教官',
        APIkeys: 'API keys',
        document: 'ファイル',
    }
}